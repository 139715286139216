<template>
  <div class="inspect-detail">
    <van-nav-bar left-arrow :title="title" @click-left="$router.back()">
      <template v-if="type === 'edit'" #right>
        <span class="right-btn" @click="editable = !editable">{{
          editable ? "取消" : "编辑"
        }}</span>
      </template>
    </van-nav-bar>
    <van-form
      ref="van-form"
      :show-error-message="false"
      label-width="50vw"
      :class="[
        'van-form__text-color',
        editable
          ? 'wrapper-height__feature-btn'
          : 'wrapper-height__not-feature-btn'
      ]"
    >
      <!--   部门   -->
      <van-field
        v-model="info.checkedDepartmentName"
        name="checkedDepartmentName"
        label="被查部门"
        input-align="right"
        readonly
        :required="editable"
        :disabled="!editable"
        placeholder="请选择"
        :rules="[{ required: true, message: '部门名称' }]"
        @click="onDepartmentClick"
      />
      <!--      <div class="line-block"></div>-->
      <van-collapse v-model="activeNames" :border="false">
        <van-collapse-item title="排查项内容" name="1">
          <van-list v-model="isLoading" :finished="isFinished">
            <van-cell
              v-for="(item, idx) in pageCheckItems"
              :key="idx"
              @click="showDetails(item, idx)"
            >
              <div class="van-collapse__row">
                <div class="van-collapse__row--top">
                  <span
                    class="van-collapse__row-left"
                    v-html="item.content"
                  ></span>
                  <span
                    v-if="item.checkStatus !== null && +item.checkStatus === 0"
                    class="van-collapse__row-trips not-standard-color"
                    >不符合</span
                  >
                  <span
                    v-if="item.checkStatus !== null && +item.checkStatus === 1"
                    class="van-collapse__row-trips success-color"
                    >符合</span
                  >
                </div>
                <div class="van-collapse__row--bottom">
                  <p class="van-collapse__row-left">
                    <span class="van-collapse__row-left-span ellipsis-single">
                      {{ item.classifyName }}
                    </span>
                    <span>|</span>&emsp;
                    <span class="van-collapse__row-left-span-2 ellipsis-single">
                      {{ item.classifyItemName }}
                    </span>
                  </p>
                  <van-icon name="arrow" />
                </div>
              </div>
            </van-cell>
          </van-list>
          <van-pagination
            v-model="checkItemsQueryInfo.page"
            :total-items="checkItems.length"
            :items-per-page="checkItemsQueryInfo.size"
            force-ellipses
            prev-text="."
            next-text="."
            show-page-size="3"
            class="van-collapse__pagination"
            @change="processPageCheckItems"
          />
        </van-collapse-item>
      </van-collapse>
      <van-field
        v-model="info.remark"
        rows="2"
        autosize
        label="备注"
        type="textarea"
        maxlength="500"
        :placeholder="editable ? '请输入内容' : ''"
        :show-word-limit="editable"
        :disabled="!editable"
      />
      <div v-show="editable" class="details-page__footer-button-wrapper--fixed">
        <van-button block round type="info" @click="onSubmit">提交</van-button>
      </div>
    </van-form>
    <department-tree
      v-model="info.checkedDepartment"
      :visible="departmentVisible"
      title="被查部门"
      @close="departmentVisible = false"
      @confirm="onDepartmentConfirm"
    />
    <check-item-details-popup
      :visible="checkItemDetailsPopupVisible"
      :editable="editable"
      :check-items-info="checkItemsInfo"
      :plan-info="planInfo"
      :item-index="currentRelateIndex"
      @close="onPopupClose"
    />
    <van-dialog
      v-model="dialogVisible"
      :show-confirm-button="false"
      class="standard-dialog"
      @closed="dialogContent = ''"
    >
      <template #title>
        <van-icon
          name="danchuangguanbi"
          class-prefix="iconfont"
          class="standard-dialog__close-icon"
          @click="onDialogClose"
        />
      </template>
      <div class="standard-dialog__content">
        <h3>检查标准</h3>
        <p>{{ dialogInfo.standard }}</p>
        <h3>参考依据</h3>
        <p>{{ dialogInfo.gist }}</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  getInfoPlansById,
  createInspectRecords,
  updateInspectRecordsById,
  getInfoInspectRecordById
} from "@/api/improve/improve";
import { operateDialog, operateMessage } from "@/utils";
import dayjs from "dayjs";
import { Toast } from "vant";
import CheckItemDetailsPopup from "./components/CheckItemDetailsPopup";
import { mapState, createNamespacedHelpers } from "vuex";
const { mapActions, mapState: improvePlanState } = createNamespacedHelpers(
  "improvePlan"
);

export default {
  name: "InspectDetail",
  components: { CheckItemDetailsPopup },
  props: ["id", "planId"],
  data() {
    return {
      editable: !this.id,
      planInfo: {},
      checkItems: [],
      pageCheckItems: [],
      // 检查类型展开
      activeClassify: [],
      // 检查内容展开
      activeItems: [],
      info: {
        checkedDepartment: "",
        checkedDepartmentName: ""
      },
      departmentVisible: false,
      dialogVisible: false,
      dialogInfo: {},
      // 折叠面板
      activeNames: ["1"],
      isLoading: false,
      isFinished: true,
      total: 100,
      checkItemsQueryInfo: { page: 1, size: 4 },
      checkItemDetailsPopupVisible: false,
      checkItemsInfo: {},
      currentRelateIndex: 0
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    ...improvePlanState({
      checkItemsStore: state => state.checkItems,
      selectedTroubleIds: state => state.selectedTroubleIds
    }),
    title() {
      return this.id ? this.planInfo.name || "--" : "新建排查";
    },
    type() {
      return this.id ? "edit" : "add";
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== "ImproveSelectTrouble") {
      next(vm => {
        vm.getPlanInfo();
      });
    } else {
      next(vm => {
        vm.$nextTick(() => {
          vm.checkItemDetailsPopupVisible = true;
        });
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "ImproveSelectTrouble") {
      this.clearCheckItems();
    }
    if (to.name === "ImprovePlanDetail") {
      this.info = {};
    }
    next();
  },
  activated() {
    this.editable = !this.id;
    this.getPlanInfo();
  },
  methods: {
    ...mapActions(["updateCheckItems", "clearCheckItems"]),
    onPopupClose() {
      this.checkItemDetailsPopupVisible = false;
      this.processPageCheckItems(this.checkItemsQueryInfo.page);
    },
    showDetails(row) {
      this.checkItemDetailsPopupVisible = true;
      this.checkItemsInfo = row;
      this.currentRelateIndex = this.checkItemsStore.findIndex(
        item => item.id === row.id
      );
    },
    processPageCheckItems(page) {
      // 每页展示4条
      const size = 4;
      this.checkItemsQueryInfo.page = page;
      const prev = page - 1 <= 0 ? 0 : page - 1;
      this.pageCheckItems = this.checkItemsStore.slice(
        prev * size,
        page * size
      );
    },
    async getPlanInfo() {
      try {
        this.planInfo = await getInfoPlansById(this.planId);
        if (this.type === "add") {
          if (!this.checkItemsStore.length) {
            this.checkItems = this.planInfo.checkItems;
            // 更新 checkItems
            console.log(
              "%cPer第一次更新，新增",
              "background: #f55f5f; color: white; padding: 1px 10px; border-top-left-radius: 3px; border-bottom-left-radius: 3px;"
            );
            this.updateCheckItems(this.checkItems);
            this.processPageCheckItems(1);
          }
        } else {
          await this.getInfo();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getInfo() {
      try {
        try {
          this.info = await getInfoInspectRecordById(this.id);
          this.info.items = this.info.checkItems.map(x => {
            const item = { ...x.item };
            return {
              ...x,
              ...item,
              id: item.checkItemId,
              itemRecordId: item.id
            };
          });
          if (!this.checkItemsStore.length) {
            this.checkItems = this.info.items;
            this.checkItems.forEach(
              item => (item.troubleIdArr = item.troubleId)
            );
            console.log(
              "%cPer第一次更新，编辑",
              "background: #f55f5f; color: white; padding: 1px 10px; border-top-left-radius: 3px; border-bottom-left-radius: 3px;"
            );
            this.updateCheckItems(this.checkItems);
            this.processPageCheckItems(1);
          }
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    },
    processCheckItems() {
      const checkItem = {
        taskRecordId: "",
        checkItemId: "",
        itemRecordId: "",
        checkStatus: "",
        // 关联的改善
        troubleIds: ""
      };
      // return this.checkItems.map(item => {
      return this.checkItemsStore.map(item => {
        return {
          ...checkItem,
          ...item,
          checkItemId: item.id,
          checkStatus: item.checkStatus,
          itemRecordId: item.planItemRecordsId,
          troubleIds: item.troubleIdArr && item.troubleIdArr.join(",")
        };
      });
    },
    onDepartmentClick() {
      if (!this.editable) return;
      this.departmentVisible = true;
    },
    async onSubmit() {
      try {
        await this.$refs["van-form"].validate();
        // 校验 checkItems
        const verify = this.checkItemsStore.every(item => {
          if (item.checkStatus === 0) {
            return item.troubleIdArr && !!item.troubleIdArr.length;
          }
          return true;
        });
        console.log(verify, "verify");
        if (!verify) {
          return Toast("请先上报不符合项的改善");
        }
        this.info.items = this.processCheckItems();
        const { id, department, orgCode } = this.userInfo;
        this.info.checkUserId = id;
        this.info.orgCode = orgCode;
        this.info.checkTime = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
        this.info.checkedOrgCode = this.planInfo.orgCode;
        this.info.department = department;
        this.info.planId = this.planInfo.id;
        if (this.type === "add") {
          await this.onCreate();
        }
        if (this.type === "edit") {
          await this.onUpdate();
        }
      } catch (e) {
        console.log(e, "e");
      }
    },
    resetAllStatus() {
      this.activeClassify = [];
      this.activeItems = [];
      this.editable = !this.id;
    },
    async onCreate() {
      try {
        await operateDialog({ message: "确认保存检查表？", title: "" });
        const r = await createInspectRecords(this.info);
        operateMessage(r, "保存");
        if (r) {
          this.resetAllStatus();
          this.$router.back();
        }
        console.log(this.info, "this.info");
      } catch (e) {
        console.log(e);
      }
    },
    async onUpdate() {
      try {
        const r = await updateInspectRecordsById(this.id, this.info);
        operateMessage(r, "保存");
        if (r) {
          this.resetAllStatus();
          this.$router.back();
        }
      } catch (e) {
        console.log(e);
      }
    },
    onDepartmentConfirm(row) {
      this.info.checkedDepartmentName = row.label;
    },
    onStandardClick(row) {
      this.dialogVisible = true;
      this.dialogInfo = { ...row };
    },
    onDialogClose() {
      this.dialogVisible = false;
      this.dialogInfo = {};
    }
  }
};
</script>

<style lang="scss">
.inspect-detail {
  .van-form {
    overflow: auto;
  }
  .line-block {
    height: 8px;
    background-color: #eceef2;
  }
  .van-collapse {
    &__row {
      &--top,
      &--bottom {
        display: flex;
        align-items: baseline;
      }
      &--top {
        max-height: 48px;
      }
      &--bottom {
        justify-content: space-between;
        align-items: center;
      }
      &-left {
        width: 68vw;
        margin-right: 10px;
        height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &-left-span,
      &-left-span-2 {
        display: inline-block;
        vertical-align: middle;
        width: 25vw;
      }
      &-left-span-2 {
        width: 40vw;
      }
      &-trips {
        font-size: 12px;
        height: 16px;
        line-height: 18px;
        padding: 0 2px;
        transform: scale(0.9);
      }
      .not-standard-color {
        border: 1px solid #f55f5f;
        color: #f55f5f;
      }
      .success-color {
        border: 1px solid #64ae08;
        color: #64ae08;
      }
      .nonsense-color {
        border: 1px solid #919191;
        color: #919191;
      }
      &--top {
        color: $--color-black-list-page;
      }
      &--bottom {
        color: $--color-gray-list-page;
      }
    }
    &__pagination {
      margin: 20px 16px 5px;
      border: none;
      display: flex;
      // justify-content: space-between;
      justify-content: center;
      .van-pagination__item {
        min-width: 30px;
        width: 30px;
        height: 30px;
        line-height: 31px;
        border-radius: 50%;
        border: none;
        font-size: 14px;
        margin-right: 40px;
        // background-color: #d8d8d8;
        background-color: #dee5ed;
        color: #3b4664;
        &::after {
          display: none;
        }
        &:nth-last-child(2) {
          margin-right: 0;
        }
      }
      .van-pagination__item--active {
        background-color: #4f5a78;
        color: #fff;
      }
      .van-pagination__prev,
      .van-pagination__next {
        display: none;
      }
    }
  }
  .standard-dialog {
    box-sizing: border-box;
    padding: 24px;
    top: 50%;
    &__close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    &__content {
      max-height: 70vh;
      overflow-y: auto;
      font-size: 14px;
      line-height: 20px;
      h3 {
        /*font-weight: bold;*/
        /*position: sticky;*/
        /*top: 0;*/
        padding: 5px 0;
        background-color: #fff;
        margin-top: -1px;
      }
      p {
        text-indent: 2em;
      }
    }
  }
}
</style>
