<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    style="height: 80vh; overflow: auto"
    get-container="body"
    round
    position="bottom"
    v-bind="$attrs"
    class="check-item-popup"
    @open="onOpen"
    @close="onClose"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <div v-if="editable" class="check-item-popup-top">
      <van-radio-group
        v-model="innerCheckStatus"
        direction="horizontal"
        class="check-item-popup-top__popup-radio"
      >
        <van-radio :name="0">不符合</van-radio>
        <van-radio :name="1" :disabled="accordBtnDisabled">符合</van-radio>
      </van-radio-group>
      <van-button
        icon="add"
        class="check-item-popup-top__popup-btn"
        :disabled="innerCheckStatus === null || +innerCheckStatus !== 0"
        @click="onTroubleClick"
        >改善</van-button
      >
    </div>
    <van-form
      :show-error-message="false"
      label-width="26vw"
      class="van-form__text-color checkout-items-form"
    >
      <van-field
        v-model="checkItemsInfo.classifyName"
        readonly
        disabled
        label="检查类别"
        placeholder="--"
      />
      <van-field
        v-model="checkItemsInfo.classifyItemName"
        readonly
        disabled
        label="检查项目"
        placeholder="--"
      />
      <van-field
        v-model="checkItemsInfo.content"
        readonly
        disabled
        rows="2"
        label="检查内容"
        autosize
        type="textarea"
        placeholder="--"
      >
        <template #input>
          <span
            v-if="checkItemsInfo.content"
            style="color: #8c8f97;"
            v-html="checkItemsInfo.content"
          ></span>
          <span v-else>--</span>
        </template>
      </van-field>
      <!-- <van-field
        v-model="checkItemsInfo.standard"
        rows="2"
        autosize
        disabled
        label="检查标准"
        type="textarea"
        maxlength="1000"
        placeholder="--"
        readonly
      /> -->
      <van-field
        v-model="checkItemsInfo.gist"
        rows="2"
        autosize
        disabled
        label="检查依据"
        type="textarea"
        maxlength="1000"
        placeholder="--"
        readonly
      />
    </van-form>
    <div class="check-item-popup-bottom">
      <van-list
        v-if="troubleList.length"
        v-model="isLoading"
        :finished="isFinished"
        finished-text=""
        class="check-item-popup-bottom__list"
      >
        <van-swipe-cell
          v-for="(item, idx) in troubleList"
          :key="idx"
          :disabled="!editable"
          :before-close="beforeClose"
        >
          <div class="check-item-popup-bottom__row">
            <span class="check-item-popup-bottom__row-index"
              >改善{{ idx + 1 }}：</span
            >
            <span class="check-item-popup-bottom__row-title">{{
              item.name
            }}</span>
            <van-icon
              class="check-item-popup-bottom__row-icon"
              class-prefix="iconfont"
              :name="troubleStatusName(item.disposeStatus)"
              :color="troubleStatusColor(item.disposeStatus)"
            />
          </div>
          <template #right>
            <van-button
              square
              type="danger"
              style="border-radius: 1vw"
              text="删除"
              @click="onDelete(item, idx)"
            />
          </template>
        </van-swipe-cell>
      </van-list>
    </div>
  </van-popup>
</template>

<script>
import { getListTroubleByIds } from "@/api/improve/improve";
import { operateDialog } from "@/utils";
import { TROUBLE_ICON_STATUS } from "@/views/trouble/utils/constant";
import { createNamespacedHelpers, mapState } from "vuex";
const { mapActions, mapState: improvePlanState } = createNamespacedHelpers(
  "improvePlan"
);
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "CheckItemDetailsPopup",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    planInfo: Object,
    itemIndex: Number
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    ...improvePlanState({
      checkItemsStore: state => state.checkItems
    }),
    checkItemsInfo() {
      console.log(this.checkItemsStore, "this.checkItemsStore");
      return this.checkItemsStore[this.itemIndex] || {};
    },
    troubleStatusName() {
      return status => {
        if (status) return TROUBLE_ICON_STATUS[status]["iconName"];
        return "";
      };
    },
    troubleStatusColor() {
      return status => {
        if (status) return TROUBLE_ICON_STATUS[status]["iconColor"];
        return "";
      };
    },
    accordBtnDisabled() {
      const troubleIdArr = this.checkItemsInfo.troubleIdArr;
      return troubleIdArr && !!troubleIdArr.length;
    }
  },
  data() {
    return {
      innerCheckStatus: "",
      troubleList: [],
      isLoading: false,
      isFinished: true,
      deleteId: "",
      deleteIndex: 0
      // visible: true
    };
  },
  watch: {
    innerCheckStatus(val) {
      const checkItems = cloneDeep(this.checkItemsStore);
      checkItems[this.itemIndex].checkStatus = val;
      console.log(
        "%cPer更新状态",
        "background: #f55f5f; color: white; padding: 1px 10px; border-top-left-radius: 3px; border-bottom-left-radius: 3px;"
      );
      console.log(checkItems, "=-=-=-=-=-=-");
      this.updateCheckItems(checkItems);
    }
  },
  methods: {
    ...mapActions(["updateCheckItems", "asyncTroubleIds"]),
    onClose() {
      this.$emit("close");
    },
    onOpen() {
      // console.log(this.checkItemsInfo.checkStatus, "open -> this.checkItemsInfo.checkStatus");
      this.innerCheckStatus = this.checkItemsInfo.checkStatus;
      this.getBindTrouble();
    },
    async getBindTrouble() {
      try {
        const item = this.checkItemsStore[this.itemIndex];
        const troubleIdArr = item.troubleIdArr;
        const ids = (troubleIdArr && troubleIdArr.join()) || "";
        this.troubleList = await getListTroubleByIds(ids);
      } catch (e) {
        console.log(e);
      }
    },
    onDelete({ id }, idx) {
      this.deleteId = id;
      this.deleteIndex = idx;
    },
    beforeClose({ position, instance }) {
      switch (position) {
        case "left":
        case "cell":
        case "outside":
          instance.close();
          break;
        case "right":
          operateDialog({
            message: "确认删除改善？"
          }).then(async () => {
            instance.close();
            const checkItems = cloneDeep(this.checkItemsStore);
            const troubleIdArr = checkItems[this.itemIndex].troubleIdArr;
            troubleIdArr.splice(troubleIdArr.indexOf(this.deleteId), 1);
            checkItems[this.itemIndex].troubleIdArr = troubleIdArr;
            console.log(
              "%cPer更新关联改善",
              "background: #f55f5f; color: white; padding: 1px 10px; border-top-left-radius: 3px; border-bottom-left-radius: 3px;"
            );
            this.updateCheckItems(checkItems);
            // 删除改善列表
            this.troubleList.splice(this.deleteIndex, 1);
            this.deleteId = "";
          });
          break;
      }
    },
    async onTroubleClick() {
      this.$emit("close");
      await this.$nextTick();
      await this.$router.push({
        name: "ImproveSelectTrouble",
        params: { planId: this.planInfo.id },
        query: { itemIndex: this.itemIndex }
      });
    }
  }
};
</script>

<style lang="scss">
.check-item-popup {
  padding-top: 9px;
  &-top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    line-height: 60px;
    .van-radio--horizontal {
      margin-right: 40px;
    }
    &__popup-btn {
      background-color: transparent;
      color: #3278ff;
      border: none;
      height: 20px;
      line-height: 20px;
    }
  }
  .checkout-items-form {
    position: relative;
  }
  .checkout-items-form::after,
  .checkout-items-form::before {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 4.26667vw;
    left: 4.26667vw;
  }
  .checkout-items-form::after {
    border-bottom: 1px solid #d8d8d8;
    bottom: 0;
    transform: scaleY(0.5);
    display: none;
  }
  .checkout-items-form::before {
    border-top: 1px solid #818181;
    top: 0;
  }
  .check-item-popup-bottom {
    padding-bottom: 20px;
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 17px;
      box-sizing: border-box;
      border-bottom: 1.5px solid #e7e8ea;
      font-size: 13px;
      &-icon {
        font-size: 32px;
        line-height: 32px;
      }
      &-index {
        width: 60px;
      }
      &-title {
        width: 200px;
        line-height: 1.5;
      }
    }
    &__list {
      margin: 0 16px;
      border: 1px solid #e7e8ea;
      border-radius: 6px;
      & .van-swipe-cell:nth-last-child(2) .check-item-popup-bottom__row {
        border: none;
      }
      .van-swipe-cell__right {
        right: -1px;
      }
    }
  }
}
</style>
